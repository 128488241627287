const dev = {
  s3: {
    BUCKET: "aplus.s3.amazonaws.com",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL:
      process.env.REACT_APP_SERVER_URL ||
      "https://api-staging.belugahealth.com",
  },
};

const prod = {
  s3: {
    BUCKET: "stendraprod209.s3.amazonaws.com",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.belugahealth.com",
  },
};
// eslint-disable-next-line import/no-anonymous-default-export
export const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

// eslint-disable-next-line import/no-anonymous-default-export
export const apiKey =
  process.env.REACT_APP_STAGE === "prod"
    ? "4CWnwOqwa45g3y4FgbDr"
    : "XG04WcCsncBg5Iq8PEcR";
