export const questionnaire = [
  {
    question: "What is your sex assigned at birth?",
    answers: [
      // TODO: Pregnant + breastfeeding Follow Up Q
      {
        answer: "Female",
      },
      {
        answer: "Male",
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question:
      "Are you currently taking the medication(s) that you are requesting?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "When was your last visit with your doctor?",
    answers: [
      {
        answer: "Less than 6 months ago",
        showConditional: false,
      },
      {
        answer: "Over 6 months ago",
        showConditional: false,
      },
      {
        answer: "I’ve never seen a doctor",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Are you having any side effects related to your current medications?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Tell me more",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },

  {
    question:
      "Please list what you're allergic to and the reaction that each allergy causes.",
    answers: ["allergies"],
    subText:
      "Include any allergies to food, dyes, prescriptions or over the counter medicines (e.g. antibiotics, allergy medications), herbs, vitamins, supplements or anything else.",
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question: "Please list your medical conditions and any prior surgeries:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements you take regularly.",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question:
      "Is there anything else you want your doctor to know about your condition or health?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Please enter anything else you want your doctor to know about your condition or health.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Are you covered by Medicaid?",
    answers: [
      {
        answer: "I AM covered by Medicaid",
        error: {
          header: "We are not currently able to serve patients who are covered under Medicaid.",
          body: "",
        },
        showConditional: false,
      },
      {
        answer: "I attest that I am not covered by Medicaid",

        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question: "",
    subText:
      `Certain medications weaken the immune system and therefore compromise the body's ability to fight infections.
This program is supported by specialists in comprehensive preventative care for sexually transmitted infections (STIs) and sexual health.
We are committed to promoting the well-being and sexual health of individuals through a range of services, including STI testing, education, and prevention.
The following questions adhere to CDC guidelines and aim to provide a comprehensive and personalized approach to care.`,
    answers: [
      {
        answer: "I understand",
        showConditional: false,
      }
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Do you take any medications that may affect your sexual health?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Have you ever been tested for HIV/AIDS?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Have you ever been diagnosed with a sexually transmitted infection (STI)?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Have you been exposed to Syphilis, Gonorrhea, Chlamydia, HIV, or Hepatitis C through social activites, blood transfusions, or needle usage in the recent past?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Do you have a rash on the palms of your hands or legs that has been present for longer than a month?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
];
